<div><h2>Wishlist Items</h2><br/>
<table>
    <tr>
      <td></td>
      <td>Masses</td>
      <td></td>
      <td><meter value="5" max="100"></meter></td>
    </tr>
    <tr>
      <td></td>
      <td>Darn Tough Socks Women's M</td>
      <td></td>
      <td><meter value="5" max="100"></meter></td>
    </tr>
</table>
</div>
