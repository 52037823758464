<div><h2>Ariel Church Wishlist</h2><br/>
    <table>
    <tr>
        <td><img src="assets/ariel-prayer-2.jpg"></td>
        <td><a href="https://www.amazon.com/Hanna-Nikole-Winter-Notched-Belted/dp/B0BBSDYVKW/ref=sr_1_7?crid=XOCPRDXIBYWU&keywords=womens%2Bdress%2Bcoats%2Bwinter&qid=1697417706&refinements=p_n_shoe_width_browse-vebin%3A492379011&rnid=492378011&s=apparel&sprefix=Womens%2Bdress%2Bcoat%2Cfashion-womens-clothing%2C98&sr=1-7&th=1&psc=1">Winter Coat</a></td>
        <td>$43</td>
        <td><meter value="0" max="43"></meter></td>
      </tr>
      <tr>
        <td></td>
        <td><s>Women's size 10 warm winter appropriate for church boots (ankle, not knee, height)</s></td>
        <td>$50</td>
        <td><meter value="50" max="50"></meter></td>
      </tr>
      <tr>
        <td></td>
        <td>Roman Catholic Daily Missal (1962)</td>
        <td>$60</td>
        <td><meter value="0" max="60"></meter></td>
      </tr>
  </table>
  </div>
