<div>
    Various items to spruce up the home
  <ul>
    <li><s>Coat Rack / Tree (mutiple, to hold 15 coats) - Can be self standing or wall mounted, but hanging points should be at various heights</s></li>
    <li>Dining Room Table and Chairs - Seats 16 - Not contemporary, but traditional looking (i.e. trim/style would match the living room). No upholestry.</li>
    <li>Water bottle, metal, easy clean, that seals completely (no convienence holes for straws, etc.)</li>
    <li><s>Non-fancy everyday silverware, dishwashable, to supplement current everyday set</s></li>
    <li><s>Size 6 (W) muck boots</s></li>
    <li>Silicon Oven Mitt replacements (ours have formed holes)</li>
  </ul>
  </div>